<template>
  <div class="shop-event-show container-lg mt-3">
    <ShopEventItem v-if="event" :event="event" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import ShopEventItem from '@/components/shop/event/EventItem.vue'
import { Event } from '@/store/modules/event/interfaces'
import { useRoute } from 'vue-router'
import { getOne } from '@/services/api/event/api'
import { useMeta } from 'vue-meta'
import { Shop } from '@/store/modules/shop/interfaces'

export default defineComponent({
  name: 'ShopEventShow',
  props: {
    shop: {
      type: Object as PropType<Shop>
    }
  },
  components: { ShopEventItem },
  setup (props) {
    const route = useRoute()
    const eventId: string = route.params.id as string || ''
    const event = ref<Event>()

    async function getEvent () {
      try {
        event.value = await getOne(eventId)
        var myHeaders = new Headers()
        myHeaders.append('Content-Type', 'image/jpeg')
      } catch (e) {
        console.log(e)
      }
    }
    getEvent()

    useMeta(computed(() => ({
      title: `Home | ${props.shop?.name.toUpperCase() || 'SHOP-VOR-ORT.DE'}`,
      description: `${event.value?.name}`,
      meta: [
        {
          name: 'description',
          content: `${event.value?.name}`
        }
      ],
      script: [{
        vmid: 'ldjson-schema',
        innerHTML: `${JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Event',
          name: event.value?.name,
          startDate: event.value?.start,
          endDate: event.value?.end,
          eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
          eventStatus: 'https://schema.org/EventScheduled',
          location: {
            '@type': 'Place',
            name: 'Bürgerhaus Windberge',
            address: {
              '@type': 'PostalAddress',
              streetAddress: 'Friedhofsweg',
              addressLocality: 'Windberge',
              postalCode: '39517',
              addressRegion: 'ST',
              addressCountry: 'DE'
            }
          },
          description: event.value?.desc,
          organizer: {
            '@type': 'Organization',
            name: 'Shop-Vor-Ort.de',
            url: 'https://www.shop-vor-ort.de'
          }
        })}`,
        type: 'application/ld+json'
      }]
    })))

    return {
      event
    }
  }
})
</script>
