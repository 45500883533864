<template>
  <div class="card">
    <h3 class="card-header align-items-center">
      {{ event.name }}
    </h3>
    <div class="card-body">
      <div class="row row-eq-height align-items-center">
        <div class="col-md-5 mb-1 fw-bold text-start">{{ event.desc }}</div>
        <div class="col-6 col-md-3">
          <strong>Beginn</strong><br>
          {{ getDate(event.start) }}<br>
          {{ getTime(event.start) }} Uhr
        </div>
        <div class="col-6 col-md-3">
          <strong>Ende</strong><br>
          {{ getDate(event.end) }}<br>
          {{ getTime(event.end) }} Uhr
        </div>
      </div>
    </div>
  </div>
  <div class="row row-eq-height gx-3 mt-3">
    <div class="col-lg-4 mb-3">
      <div class="card shadow h-100">
        <h3 class="card-header">
          Wann?
        </h3>
        <div class="card-body">
          <p class="fs-6 text-danger text-uppercase fw-bolder">Babyböerse {{ event ? getDiffNow(event.start) : "" }}</p>
          <p class="fs-6 fw-bolder"><b-icon-calendar-check /><br>{{ event ? getDate(event.start) : "Termin offen" }}</p>
          <p class="fs-6 fw-bolder"><b-icon-clock /><br>{{ event ? `${getTime(event.start)} Uhr bis ${getTime(event.end)} Uhr` : "Termin offen" }}</p>
        </div>
      </div>
    </div>
    <div class="col-lg-4 mb-3">
      <div class="card shadow h-100">
        <h3 class="card-header">
          Wo?
        </h3>
        <div class="card-body">
          <p class="fs-5 fw-bolder">Bürgerhaus Windberge</p>
          <p>39517 Windberge, Friedhofsweg</p>
        </div>
        <div class="card-footer">
          <a class="btn btn-primary shadow"
             href="https://www.google.de/maps/place/Friedhofsweg,+39517+Windberge/@52.5222553,11.7141132,993m/data=!3m2!1e3!4b1!4m13!1m7!3m6!1s0x47af3e3b0fca1657:0xe6a9b6da5709e762!2sFriedhofsweg,+39517+Windberge!3b1!8m2!3d52.5222553!4d11.7163019!3m4!1s0x47af3e3b0fca1657:0xe6a9b6da5709e762!8m2!3d52.5222553!4d11.7163019"
             target="_blank"><b-icon-geo-alt-fill /> zur Karte</a>
        </div>
      </div>
    </div>
    <div class="col-lg-4 mb-3">
      <div class="card shadow h-100">
        <h3 class="card-header">
          Was wird verkauft?
        </h3>
        <div class="card-body">
          <ul class="text-start">
            <li>Kinderbekleidung von Gr. 50 bis 188</li>
            <li>Kinderschuhe, Kinderstiefel ...</li>
            <li>Spielzeug</li>
            <li>Kinderwagen, Kindersitze, Laufräder, Fahrräder ...</li>
            <li>„Zubehör“ rund ums Kind und Kleinkind</li>
            <li>u. v. m.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="row row-eq-height gx-3">
    <div class="col-sm-12 col-lg-6 mb-3">
      <div class="card shadow h-100">
        <h3 class="card-header">
          Besucher / Käufer
        </h3>
        <div class="card-body">
          <h4 class="text-danger text-uppercase fw-bolder">
            Wichtiger Hinweis zu COVID-19!
          </h4>
          <ul class="text-start">
            <li>Für den Besuch der Börse gilt die <span class="fs-4 fw-bolder">Maskenpflicht.</span></li>
            <li>Es müssen die geltenden Schutzmaßnahmen und ein Sicherheitsabstand von mindestens 1,5 Metern eingehalten werden.</li>
            <li>Bei Krankheitssymptomen bitten wir Sie, zum Schutze aller, auf den Besuch der Babybörse zu verzichten.</li>
          </ul>
        </div>
        <div class="card-footer">
          <router-link class="btn btn-primary shadow" :to="{ name: 'Besucher' }">Weitere Informationen für Besucher</router-link>
        </div>
      </div>
    </div>
    <div class="col-sm-12 col-lg-6 mb-3">
      <div class="card shadow h-100">
        <h3 class="card-header">
          Verkäufer
        </h3>
        <div class="card-body">
          <h4 class="text-danger text-uppercase fw-bolder">Du möchtest etwas verkaufen?</h4>
          <p class="fw-bolder">Wer als Verkäufer an unserer Börse teilnehmen möchte, registriert sich auf unserer Seite und hinterlegt seine Adressdaten. Ganz einfach!</p>
          <p><strong>
            Die Anmeldefrist endet am
            {{ event ? getDiff(event.start, -13, 'dddd, D. MMM YYYY') : '' }}.
          </strong></p>
          <p><strong>
            Die Vergabe der Verkäufernummern erfolgt am
            {{ event ? getDiff(event.start, -12, 'dddd, D. MMM YYYY') : '' }}
            per Auslosung.</strong></p>
          <p>Alle ausgelosten Verkäufer werden von uns per E-Mail über die weiteren Details informiert.</p>
        </div>
        <div class="card-footer">
          <router-link class="btn btn-primary shadow" :to="{ name: 'Verkaeufer' }">Informationen & Registrierung für Verkäufer</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import moment from 'moment'
import { Event } from '@/store/modules/event/interfaces'

export default defineComponent({
  name: 'ShopEventItem',
  props: {
    event: {
      type: Object as PropType<Event>
    }
  },
  setup () {
    function getDate (date: Date) {
      return moment(date).format('LL')
    }
    function getTime (date: Date) {
      return moment(date).format('LT')
    }
    function getDiff (date: Date, days: number, format: string) {
      return moment(date).add(days, 'd').format(format)
    }
    function getDiffNow (start: Date) {
      const now = new Date()
      if (moment(start) < moment(now)) {
        return 'aktuell geöffnet'
      }
      return moment(start).fromNow()
    }
    return {
      getDate,
      getTime,
      getDiff,
      getDiffNow
    }
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
