
import { defineComponent, PropType } from 'vue'
import moment from 'moment'
import { Event } from '@/store/modules/event/interfaces'

export default defineComponent({
  name: 'ShopEventItem',
  props: {
    event: {
      type: Object as PropType<Event>
    }
  },
  setup () {
    function getDate (date: Date) {
      return moment(date).format('LL')
    }
    function getTime (date: Date) {
      return moment(date).format('LT')
    }
    function getDiff (date: Date, days: number, format: string) {
      return moment(date).add(days, 'd').format(format)
    }
    function getDiffNow (start: Date) {
      const now = new Date()
      if (moment(start) < moment(now)) {
        return 'aktuell geöffnet'
      }
      return moment(start).fromNow()
    }
    return {
      getDate,
      getTime,
      getDiff,
      getDiffNow
    }
  }
})
